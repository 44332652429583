<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-date-picker
                        v-model="filters.day"
                        type="date"
                        placeholder="罚款日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-date-picker
                        v-model="submitDateRange"
                        type="daterange"
                        placeholder="登记日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd()" :disabled="$store.state.org.type != 2">罚款登记</el-button>
                    </div>
                </div>
                <div class="datatable">
                    <el-table
                        ref="curChargeTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="50">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="projectName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            label="分包单位名称">
                        </el-table-column>
                        <el-table-column
                            prop="revenue"
                            label="罚款金额"
                            align="left"
                            min-width="100">
                        </el-table-column>
                        <el-table-column
                            prop="day"
                            align="center"
                            label="罚款日期"
                            min-width="110">
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            align="center"
                            label="登记人"
                            min-width="80">
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            align="center"
                            label="登记时间"
                            min-width="150">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="150">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="罚款登记"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ChargeAdd :params="addDialog.params" @done="handleAddDone"></ChargeAdd>
            </el-dialog>

            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ChargeEdit :params="editDialog.params" @done="handleEditDone"></ChargeEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import ChargeAdd from './FineRecordAdd'
import ChargeEdit from './FineRecordEdit'

export default {
    name: 'FineRecord',
    components: {
        ChargeAdd,
        ChargeEdit
    },
    data() {
        return {
            supplierList: [],

            filters: {
                supplierId: '',
                day: '',
                startDate: '',
                endDate: '',
            },

            submitDateRange: [],

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();
    },
    watch: {
        submitDateRange (value) {
            if (null == value || value.length < 2) {
                this.filters.startDate = "";
                this.filters.endDate = "";
                return;
            }
            this.filters.startDate = value[0];
            this.filters.endDate = value[1];
        }
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.projectId = this.$store.state.org.id;
            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/punish/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.revenue = this.toFixed(item.revenue, 2);
                        item.createTime = item.createTime.substring(0, 16);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true, visible: false, params: { }  };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true, params: { }  };
            });
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        handleDelete(index, row) {
            this.confirm('确认删除？', function () {
                let data = {
                    id : row.id
                };
                http.post('v1/punish/delete', data).then(req => {
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },
    }
}
</script>

<style scoped>
</style>
