<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="罚款金额" prop="money">
                        <el-input type="text" v-model="form.money" size="small" maxlength="15" @blur="fieldTrim(form, 'money')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="罚款日期" prop="date">
                        <el-date-picker
                            v-model="form.date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="附件" prop="date">
                        <el-upload
                            :action="uploadUrl"
                            :on-remove="handleRemove"
                            :on-exceed="handleExceed"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            :on-change="handleChange"
                            multiple
                            :limit="5"
                            :file-list="initFileList">
                            <el-button size="small" type="primary">选择文件</el-button>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="500" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'FineRecordAdd',
    data(){
        return {
            supplierList: [],
            initFileList: [],
            fileList: [],
            form: {
                supplierId: '',
                money: '',
                date: '',
                remark: '',
                file: '',
            },
            rules: {
                supplierId: [{required: true, message: '分包单位不能为空', trigger: 'blur'}],
                money: [
                    {required: true, message: '罚款金额不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkMoney, trigger: 'blur'},
                ],
                date: [{required: true, message: '罚款日期不能为空', trigger: 'blur'}],
            },
            submitting: false,
            pickerOptions: {
                disabledDate(time) {
                    let now = new Date();
                    return time.getTime() > now.getTime() 
                        || !(time.getFullYear() == now.getFullYear() && time.getMonth() == now.getMonth());
                }
            },
            uploadUrl: http.BASE_URL + 'v1/upload',
        }
    },
    created() {
        this.loadSupperList();
    },
    mounted() {
        let uploadListDom = this.$el.querySelector('.el-upload-list');
        uploadListDom.addEventListener('click', this.handleDownLoad, false);
    },
    beforeDestroy() {
        let uploadListDom = this.$el.querySelector('.el-upload-list');
        uploadListDom.removeEventListener('click', this.handleDownLoad, false);
    },
    methods: {
        ...common,
        ...verify,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};
                    data.projectId = this.$store.state.org.id;
                    data.supplierId = this.form.supplierId;
                    data.revenue = this.form.money;
                    data.day = this.form.date;
                    data.remark = this.form.remark;
                    data.file = this.form.file;

                    http.post('v1/punish/add', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleRemove(file, fileList) {
            this.updateFileList(fileList);
        },
        handleSuccess(response, file, fileList) {
            let data = file.response;
            if (data.code != 0) {
                this.showError(data.msg);
                return;
            }
            this.updateFileList(fileList);
        },
        handleChange(file, fileList) {
            let domList = this.$el.querySelectorAll('.el-upload-list li .el-icon-close');
            let arr = [];
            for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].response && fileList[i].response.code != 0) {
                    arr.push(domList[i]);
                }
            }
            for (let i = 0; i < arr.length; i++) {
                arr[i].dispatchEvent(new MouseEvent('click'));
            }
        },
        // handleError(err, file, fileList) {
        handleError(err, file) {
            this.showError(file.name + " 上传失败");
        },
        handleExceed() {
            this.showError("最多允许上传5个附件");
        },
        updateFileList(fileList) {
            let arr = [];
            fileList.forEach(file => {
                let { code, data } = file.response;
                if (code == 0) {
                    arr.push({ name: data.originalName, url: data.url, });
                }
            });
            this.form.file = JSON.stringify(arr);
            this.fileList = arr;
        },
        handleDownLoad(ev) {
            let aDomList = this.$el.querySelectorAll('.el-upload-list li a');
            if (ev.target.tagName.toLowerCase() == 'a') {
                let i = -1;
                for (i = 0; i < aDomList.length; i++) {
                    if (aDomList[i] == ev.target) {
                        break;
                    }
                }
                if (i != -1 && i < this.fileList.length) {
                    let file = this.fileList[i];
                    let { data } = file.response? file.response: { data: file };
                    let filename = data.originalName || data.name;
                    let url = data.url;
                    http.download(url, { filename: filename, type: "application/octet-stream" });
                }
            }
        },
    }
};
</script>

<style scoped>
</style>